<template>
  <div class="containerx">
    <!--  环境检测  -->
    <!-- 修改设备dialog -->
    <el-dialog width="30%" title="修改设备" :visible.sync="edit_p_show">
      <el-form ref="editRefs" :model="edit_p_obj" label-width="100px">
        <!-- <el-form-item label="安装地址">
          <el-input v-model="edit_p_obj.address"></el-input>
        </el-form-item> -->
        <el-form-item label="生产厂家" prop="manufactor">
          <el-input v-model="edit_p_obj.manufactor"></el-input>
        </el-form-item>
        <el-form-item label="设备名称" prop="name">
          <el-input v-model="edit_p_obj.name"></el-input>
        </el-form-item>
        <el-form-item label="生产日期" prop="productionData">
          <el-input v-model="edit_p_obj.productionData"></el-input>
        </el-form-item>
        <el-form-item label="设备编号" prop="serialNumber">
          <el-input v-model="edit_p_obj.serialNumber"></el-input>
        </el-form-item>
        <!-- 				<el-form-item label="规格型号" prop="specifications">
					<el-input v-model="edit_p_obj.specifications"></el-input>
				</el-form-item>
				<el-form-item label="电压等级" prop="voltageLevel">
					<el-input v-model="edit_p_obj.voltageLevel"></el-input>
				</el-form-item> -->
        <el-form-item label="物联编号" prop="monitorSerialNumber">
          <el-input v-model="edit_p_obj.monitorSerialNumber"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="edit_p_show = false">取 消</el-button>
        <el-button type="primary" @click="confirmEditP">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 设备列表 -->
    <div class="sblb">
      <div class="btngrp">
        <div @click="hjjc(item, index)" :class="hjjc_current==index?'btn1':'btn2'" v-for="(item, index) in hjjc_arr">
          {{item}}
        </div>
        <slot :msg="hjjc_current"></slot>
      </div>
      <div class="search_box">
        <div>
          <label style="font-size: 0.9rem;margin-left:2rem;">设备类型:</label>&nbsp;
          <template>
            <el-select class="monitor-select" v-model="typeId" filterable :clearable="true" placeholder="请选择类型"
              @change="onChangeType" :popper-append-to-body="false">
              <el-option v-for="item in equipmentTypeList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </template>
        </div>
        <div style="display: flex;align-items: center;">
          <span class="lable_text"
            style="display: flex;font-size: 0.9rem;margin-left: 1rem;margin-right: .5rem;">设备编号:</span>
          <div>
            <el-input placeholder="请输入编号" @blur="inputSn" @clear="clearSn" v-model="equipmentSn" autocomplete="off" clearable></el-input>
          </div>
        </div>
      </div>
      <div class="title">设备列表</div>
      <div class="table">
        <div class="tr bg">
          <div class="td1">
            <p>
              <el-checkbox :indeterminate="isIndeterminate" v-model="allCheck" @change="selectAll($event)">
              </el-checkbox>
            </p>
          </div>
          <div class="td1">
            <p>序号</p>
          </div>
          <div class="td2">
            <p>设备名称</p>
          </div>
          <div class="td3 td">
            <p>设备编号</p>
          </div>
          <div class="td4 td">
            <p>物联编号</p>
          </div>
          <!-- 					<div class="td4 td">
						<p>规格型号</p>
					</div>
					<div class="td5 td">
						<p>电压等级</p>
					</div> -->
          <div class="td6 td">
            <p>设备状态</p>
          </div>
          <div class="td7 td">
            <p>生产日期</p>
          </div>
          <div class="td8 td">
            <p>生产厂家</p>
          </div>
          <div class="td9 td">
            <p style="margin-left: 5rem;">操作</p>
          </div>
        </div>
        <draggable v-model="tableData" @change="log" :options="{animation:100}">
          <div @click="handleTable(item)" style="cursor: pointer;" class="tr" v-for="(item, index) in tableData"
            :class="index % 2 == 0?'bg1':'bg2'" :key="item.id">
            <div class="td1" @click.stop="clickCheckbox($event)">
              <el-checkbox v-model="item.checked" @change="changeEquipmentId($event,index)"></el-checkbox>
            </div>
            <div class="td1">
              <p>{{ index+1 }}</p>
            </div>
            <div class="td2">
              <div class="z1">
                <div class="item-show">{{item.name}}</div>
                <div class="hidden-text">{{item.name}}</div>
              </div>
            </div>
            <div class="td3 td">
              <div class="z1">
                <div class="item-show">{{item.serialNumber}}</div>
                <div class="hidden-text">{{item.serialNumber}}</div>
              </div>
            </div>
            <div class="td4 td">
              <div class="z1">
                <div class="item-show">{{item.monitorSerialNumber}}</div>
                <div class="hidden-text">{{item.monitorSerialNumber}}</div>
              </div>
            </div>
            <!-- 						<div class="td4 td">
							<div class="z1">
								<div class="item-show">{{item.specifications}}</div>
								<div class="hidden-text">{{item.specifications}}</div>
							</div>
						</div>
						<div class="td5 td">
							<p>{{item.voltageLevel}}</p>
						</div> -->
            <div class="td6 td">
              <p :style="{color: item.state==0?'#05CE31':(item.state==1?'#F2C938':'#F63737')}">
                {{rtState(item.state)}}
              </p>
            </div>
            <div class="td7 td">
              <p>{{item.productionData}}</p>
            </div>
            <div class="td8 td">
              <div class="z1">
                <div class="item-show">{{item.manufactor}}</div>
                <div class="hidden-text">{{item.manufactor}}</div>
              </div>
            </div>
            <div @click.stop="editP(item)" class="td9 td">
              <p style="margin-left: 5rem;color: #2BC9DE;">修改</p>
            </div>
          </div>
        </draggable>

      </div>
      <div class="pagination">
        <el-pagination style="color: white" background :page-sizes="[10, 15, 20]" @current-change="currentChange"
          @size-change="sizeChange" :page-size="facility_size" :current-page.sync="facility_page"
          layout="total, sizes, prev, pager, next, jumper" :total="facility_total">
        </el-pagination>
      </div>

    </div>
  </div>
</template>

<script>
  import * as echarts from 'echarts';
  import draggable from 'vuedraggable'
  export default {
    name: 'index1',
    props: {
      // 项目id
      projectId: {
        type: Number,
        default: null
      },
      projects: {
        type: Number,
        default: null
      },
      // 层级id
      levelId: {
        type: Number,
        default: null
      }
    },
    components: {
      draggable
    },
    data() {
      return {
        edit_p_show: false,
        edit_p_obj: {
          "address": "",
          "id": 0,
          "manufactor": "",
          "name": "",
          "parameter": "",
          "productionData": "",
          "serialNumber": "",
          "specifications": "",
          "voltageLevel": "",
          "monitorSerialNumber": "",
        },
        tableData: [],
        sssj_current: 0,
        hjjc_arr: ['环境设备', '能源设备'],
        hjjc_current: 0,
        facility_type: 1,
        facility_page: 1,
        facility_size: 10,
        facility_total: 0,
        selectEquipmentIds: [],
        allCheck: false,
        isIndeterminate: false,
        equipmentTypeList: [],
        typeId: null,
        equipmentSn: null,
        // 设备id
        facilityId: 1,
        sssj_obj: {},
        type: 0, // 环境设备0能源设备1
        // 图标横纵坐标
        xData: [],
        yData: [],
        // 日期
        beginTime: '',
        endTime: '',
        date: ['', ''],
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
      }
    },
    methods: {
      clickCheckbox(event) {
        event.stopPropagation();
      },
      //全选/不选
      selectAll(res) {

        this.selectEquipmentIds = []
        this.isIndeterminate = false;

        if (res) {
          for (var i = 0; i < this.tableData.length; i++) {
            this.selectEquipmentIds.push(this.tableData[i].id)
            this.tableData[i].checked = true
          }
        } else {
          for (var i = 0; i < this.tableData.length; i++) {
            this.tableData[i].checked = false
          }
        }

        var ids = this.selectEquipmentIds
        this.$emit("handleSelectEquipment", ids)

      },
      changeEquipmentId(res, index) {

        if (res) {
          this.selectEquipmentIds.push(this.tableData[index].id)
          if (this.tableData.length == this.selectEquipmentIds.length) {
            this.allCheck = true
          }
        } else {
          this.selectEquipmentIds = this.selectEquipmentIds.filter((eid) => {
            return eid != this.tableData[index].id
          })
          this.allCheck = false
        }
        var ids = this.selectEquipmentIds
        this.$emit("handleSelectEquipment", ids)

        this.isIndeterminate = this.selectEquipmentIds.length > 0 && this.selectEquipmentIds.length < this.tableData
          .length;

      },
      log(e) {
        this.PX()
      },
      sortNum(arr) {
        arr.sort(function(a, b) {
          return b - a;
        });
      },
      PX() {
        let _this = this
        let narr = []
        let narr1 = []
        for (let i = 0; i < _this.tableData.length; i++) {
          narr1.push(_this.tableData[i].sort)
        }
        this.sortNum(narr1);
        for (let i = 0; i < _this.tableData.length; i++) {
          let obj = {
            id: _this.tableData[i].id,
            sort: narr1[i]
          }
          narr.push(obj)
        }

        // return
        this.$newPost('/equipment/sort', narr).then(res => {
          if (res.code == 2000) {
            this.$message({
              message: res.message,
              type: 'success'
            });
            this.getFacility()
          } else {
            this.$message({
              message: res.message,
              type: 'warning'
            });
          }
        })
      },
      // 点击修改设备
      editP(item) {
        console.log(item)
        this.edit_p_obj = JSON.parse(JSON.stringify(item))
        this.edit_p_show = true
      },
      // 修改设备
      confirmEditP() {
        this.$newPost('/equipment/update', this.edit_p_obj).then(res => {
          if (res.code == 2000) {
            this.$message({
              message: res.message,
              type: 'success'
            });
            this.edit_p_show = false
            this.getFacility()
          } else {
            this.$message({
              message: res.message,
              type: 'warning'
            });
          }
        })
      },
      handleTable(item) {
        // console.log(item)
        var obj = {
          sbId: item.id,
          hjjc_current: this.hjjc_current
        }
        this.$emit('handleTb99', obj)
      },
      // 根据设备id查询环境设备最新的数据
      getNewData() {
        this.$newGet('/online/monitoring/newest/environment/' + this.facilityId).then(res => {
          this.sssj_obj = res.data
          this.getCharts()
          this.$message({
            message: '访问成功',
            type: 'success'
          });
        })
      },
      // 设备状态：0-在线 1-离线 2-异常
      rtState(num) {
        switch (num) {
          case 0:
            return '在线'
            break;
          case 1:
            return '离线'
            break;
          default:
            return '异常'
        }
      },
      hjjc(item, index) {
        this.hjjc_current = index
        this.type = index
        this.equipmentSn = null
        this.getEquipmentType()
        this.getFacility()

      },
      // 设备列表
      getFacility() {
        // var levelId = this.projects - 1
        // if(levelId==0) {
        //   levelId = null
        // }
        var obj = {
          page: this.facility_page,
          size: this.facility_size,
          "levelId": this.levelId,
          "projectId": this.projectId,
          "type": this.type,
          "typeId": this.typeId,
          "equipmentSn": this.equipmentSn
        }
        this.$newPost('/equipment/page', obj).then(res => {
          this.tableData = res.data.records
          for (var i = 0; i < this.tableData.length; i++) {
            this.$set(this.tableData[i], 'checked', false)
          }

          this.facility_total = res.data.total
          if (res.data.records.length > 0) {
            this.facilityId = res.data.records[0].id
          }
          // this.getNewData()
        })
      },
      currentChange(e) {
        console.log(e)
        this.facility_page = e
        this.getFacility()
      },
      sizeChange(e) {
        this.facility_size = e
        this.getFacility()
      },
      inputSn(){
        this.getFacility()
      },
      clearSn(){
        this.equipmentSn = null
        this.getFacility()
      },
      // 图表
      mycharts() {
        var chartDom = document.getElementById('myChart');
        var myChart = echarts.init(chartDom);
        var option;

        option = {
          // 提示框
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#2BDEC9'
              }
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: this.xData
          },
          yAxis: {
            type: 'value'
          },
          series: [{
            areaStyle: {

            },
            itemStyle: {
              normal: { //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(47, 241, 216, 0.5)' // 0% 处的颜色
                }, {
                  offset: 0.5,
                  color: 'rgba(47, 241, 216, 0.3)' // 100% 处的颜色
                }, {
                  offset: 1,
                  color: 'rgba(76, 247, 255, 0.1)' // 100% 处的颜色
                }]), //背景渐变色
                lineStyle: { // 系列级个性化折线样式
                  width: 3,
                  type: 'solid',
                  color: "#4fd6d2"
                }
              },
            },
            data: this.yData,
            type: 'line',
            areaStyle: {}
          }]
        };

        option && myChart.setOption(option);
      },
      // 获取数据和图表
      getCharts() {
        var obj = {
          "beginTime": this.beginTime,
          "dataType": this.sssj_current,
          "endTime": this.endTime,
          "equipmentId": this.facilityId
        }
        this.$newPost('/online/monitoring/environment/data/statistics', obj).then(res => {
          console.log(res, 'rrr')
          if (res.data.length > 0) {
            this.xData = []
            this.yData = []
            res.data.forEach((item, index) => {
              this.xData.push(item.gmtCreate)
              this.yData.push(item.value)
            })
            console.log(this.xData)
            setTimeout(() => {
              this.mycharts()
            })
          } else {
            this.xData = []
            this.yData = []
            this.mycharts()
          }
        })

      },
      dateChange(e) {
        console.log(e)
        this.beginTime = e[0]
        this.endTime = e[1]
        this.getCharts()
      },
      getEquipmentType() {
        this.$newPost('/equipment/type/list/' + this.hjjc_current).then(res => {
          this.equipmentTypeList = res.data

        })
      },
      onChangeType(res) {
        this.typeId = res
        this.getFacility()
      }
    },
    mounted() {
      // this.getCharts()
      this.getFacility()
      this.getEquipmentType()
    },
    watch: {
      sssj_current(val) {
        // this.getCharts()
      },
    },

  }
</script>

<style lang="scss" scoped>
  .bg {
    background-color: rgba(43, 201, 222, 0.6);
  }

  .bg1 {
    background-color: rgba(162, 162, 162, 0.2);
  }

  .bg2 {
    background: rgba(14, 242, 242, 0.3);
  }

  .containerx {
    color: #ffffff;
    overflow: auto;
  }

  .btngrp {
    margin-left: 1rem;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .btn1 {
      width: 15rem;
      height: 8.4rem;
      line-height: 8.4rem;
      text-align: center;
      color: rgba(43, 201, 222, 1);
      border-bottom: 1px solid rgba(43, 201, 222, 1);
      font-size: 1.8rem;
      margin-right: 3rem;
      cursor: pointer;
      margin-bottom: 2rem;
    }

    .btn2 {
      width: 15rem;
      height: 8.4rem;
      line-height: 8.4rem;
      text-align: center;
      color: rgba(43, 222, 201, 0.4);
      font-size: 1.8rem;
      margin-right: 3rem;
      cursor: pointer;
      margin-bottom: 2rem;
    }
  }


  .sblb {
    background-color: #192534;
    margin: 2rem 0;
  }

  .nyjc {
    background-color: #192534;


    .sssj {
      display: flex;
      align-items: center;
      margin-left: 0.9rem;

      .item {
        display: flex;
        margin-right: 3rem;
        cursor: pointer;
      }

      .img2 {
        width: 20rem;
        height: 3.6rem;
        margin-left: 0.9rem;
      }

      .img1 {
        width: 6.4rem;
        height: 6.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0.2rem solid rgba(43, 222, 201, 0.6);
        border-radius: 0.6rem;

        img {
          width: 4rem;
          height: 4rem;
        }
      }

      .t1 {
        position: absolute;
        top: 1.05rem;
        right: 15.8rem;
        font-size: 1.6rem;
      }

      .t2 {
        position: absolute;
        top: 1.05rem;
        right: 1.5rem;
        font-size: 1.6rem;
      }
    }
  }

  .title {
    font-size: 1.8rem;
    font-weight: 500;
    margin-left: 2rem;
    //margin-top: 2rem;
    height: 5rem;
    line-height: 5rem;
  }

  .table {
    width: 100rem;
    height: 34rem;
    overflow: auto;
    margin: 0 auto;

    .tr {
      display: flex;
      // margin-top: 1rem;
      padding: .5rem 0;

      .td1 {
        font-size: 0.9rem;
        width: 6.6rem;
        height: 2rem;
        margin-right: 0.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .td2 {
        font-size: 0.9rem;
        width: 30rem;
        height: 2rem;
        display: flex;
        align-items: center;
        padding-left: 2rem;
        margin-right: 0.4rem;
      }

      .td {
        font-size: 0.9rem;
        height: 2rem;
        display: flex;
        align-items: center;
        padding-left: 2rem;
        margin-right: 0.4rem;
      }

      .td3 {
        width: 30.8rem;
      }

      .td4 {
        width: 20.8rem;
      }

      .td5 {
        width: 13.6rem;
      }

      .td6 {
        width: 8rem;
      }

      .td7 {
        width: 20.4rem;
      }

      .td8 {
        width: 23.2rem;
      }

      .td9 {
        width: 10rem;
      }
    }

    .tr:nth-child(n+2):hover {
      background-color: rgba(117, 117, 117, 0.4);
    }
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
    margin-right: 1rem;
  }

  .z1 {
    width: 100%;
    height: 2rem;

    .item-show {
      height: 2rem;
      line-height: 2rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .hidden-text {
      font-size: 1.7rem;
      position: relative;

      // width: 25rem;
      line-height: 2.2rem;
      z-index: -2;
      top: -2rem;
      padding: 8px 8px 8px 8px;
      border-radius: .2rem;
    }

  }

  .z1:hover .hidden-text {
    // display: show;
    background-color: rgba(8, 157, 157, 1);
    z-index: 999;
  }

  .date-select {
    margin-left: 15rem;
    margin-top: 4rem;
    margin-bottom: 2rem;

    /deep/ .el-input__inner {
      border-color: #2BDEC9;
      background-color: unset;
      color: #2BDEC9;

      .el-range-separator {
        color: #2BDEC9;
      }

      .el-input__icon {
        color: #2BDEC9;
      }

      input {
        background-color: unset;
        color: #2BDEC9;
      }
    }
  }

  .search_box {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    .monitor-select {
      ::v-deep .el-select {
        width: 250px;
      }

      ::v-deep .el-input__inner {
        background-color: rgba(43, 222, 201, 0.6);
        color: #fff;
        border: 0px;

      }

      ::v-deep .el-select-dropdown__item {
        color: #fff;
      }


      ::v-deep .el-scrollbar,
      ::v-deep .el-select-dropdown {
        background-color: #192534;
        color: #fff !important;
        border-color: rgba(43, 222, 201, 0.6);
      }

      ::v-deep .el-scrollbar__wrap,
      ::v-deep .el-select-dropdown__list {
        background-color: #192534;
        color: #fff !important;
      }

      ::v-deep .el-select-dropdown__item.hover,
      .el-select-dropdown__item:hover {
        background-color: rgba(43, 222, 201, 0.6);
        color: #fff;
      }
    }

  }
</style>
