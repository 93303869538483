<template scope="val">
	<div class="containerx">
		<div v-if="project==0" class="project0">
			<div class="title">公司列表</div>
			<div class="table">
				<div class="tr bg">
					<div class="td1">
						<p>序号</p>
					</div>
					<div class="td2">
						<p>公司名称</p>
					</div>
				</div>
				<div @click="handleTb0(item)" class="tr" v-for="(item, index) in tableData0"
					:class="index % 2 == 0?'bg1':'bg2'">
					<div class="td1">
						<p>{{ index+1 }}</p>
					</div>
					<div class="td2">
						<p>{{item.projectName}}</p>
					</div>
				</div>
			</div>
			<div class="pagination">
				<el-pagination style="color: white" background @current-change="currentChange0"
					@size-change="sizeChange0" :current-page.sync="current0" :page-sizes="[10, 15, 20]"
					layout="total, sizes, prev, pager, next, jumper" :total="total0">
				</el-pagination>
			</div>
		</div>
		<div v-if="project==1" class="project1">
			<div style="width: 100%;display: flex;justify-content: space-between;align-items: center;">
				<div class="title">
					<span @click="project=0" style="color: rgba(43, 222, 201, 0.4);cursor: pointer;">公司列表&nbsp; /
						&nbsp;</span>
					<span style="color: rgba(43, 201, 222, 1);cursor: pointer;">{{xmcj1}}</span>
				</div>
				<div style="display: flex;">
					<div @click="project=0" class="back">返回</div>
				</div>
			</div>
			<div class="table">
				<div class="tr bg">
					<div class="td1">
						<p>序号</p>
					</div>
					<div class="td2">
						<p>项目名称</p>
					</div>
				</div>
				<div @click="handleTb1(item)" class="tr" v-for="(item, index) in tableData1"
					:class="index % 2 == 0?'bg1':'bg2'">
					<div class="td1">
						<p>{{ index+1 }}</p>
					</div>
					<div class="td2">
						<p>{{item.levelName}}</p>
					</div>
				</div>
			</div>
			<div class="pagination">
				<el-pagination style="color: white" background @current-change="currentChange1"
					@size-change="sizeChange1" :current-page.sync="current1" :page-sizes="[10, 15, 20]"
					layout="total, sizes, prev, pager, next, jumper" :total="total1">
				</el-pagination>
			</div>

		</div>

		<div v-if="project==2" class="project1">
			<div style="width: 100%;display: flex;justify-content: space-between;align-items: center;">
				<div class="title">
					<span @click="project=0" style="color: rgba(43, 222, 201, 0.4);cursor: pointer;">公司列表&nbsp; /
						&nbsp;</span>
					<span @click="project=1" style="color: rgba(43, 222, 201, 0.4);cursor: pointer;">{{xmcj1}}&nbsp; /
						&nbsp;</span>
					<span style="color: rgba(43, 201, 222, 1);cursor: pointer;">{{xmcj2}}</span>
				</div>
				<div style="display: flex;">
					<div @click="project=1" class="back">返回</div>
				</div>
			</div>
			<div class="table">
				<div class="tr bg">
					<div class="td1">
						<p>序号</p>
					</div>
					<div class="td2">
						<p>项目名称</p>
					</div>
				</div>
				<div @click="handleTb2(item)" class="tr" v-for="(item, index) in tableData2"
					:class="index % 2 == 0?'bg1':'bg2'">
					<div class="td1">
						<p>{{ index+1 }}</p>
					</div>
					<div class="td2">
						<p>{{item.levelName}}</p>
					</div>
				</div>
			</div>
			<div class="pagination">
				<el-pagination style="color: white" background @current-change="currentChange2"
					@size-change="sizeChange2" :current-page.sync="current2" :page-sizes="[10, 15, 20]"
					layout="total, sizes, prev, pager, next, jumper" :total="total2">
				</el-pagination>
			</div>

		</div>

		<div v-if="project==3" class="project1">
			<div style="width: 100%;display: flex;justify-content: space-between;align-items: center;">
				<div class="title">
					<span @click="project=0" style="color: rgba(43, 222, 201, 0.4);cursor: pointer;">公司列表&nbsp; /
						&nbsp;</span>
					<span @click="project=1" style="color: rgba(43, 222, 201, 0.4);cursor: pointer;">{{xmcj1}}&nbsp; /
						&nbsp;</span>
					<span @click="project=2" style="color: rgba(43, 222, 201, 0.4);cursor: pointer;">{{xmcj2}}&nbsp; /
						&nbsp;</span>
					<span style="color: rgba(43, 201, 222, 1);cursor: pointer;">{{xmcj3}}</span>
				</div>
				<div style="display: flex;">
					<div @click="project=2" class="back">返回</div>
				</div>
			</div>
			<div class="table">
				<div class="tr bg">
					<div class="td1">
						<p>序号</p>
					</div>
					<div class="td2">
						<p>项目名称</p>
					</div>
				</div>
				<div @click="handleTb3(item)" class="tr" v-for="(item, index) in tableData3"
					:class="index % 2 == 0?'bg1':'bg2'">
					<div class="td1">
						<p>{{ index+1 }}</p>
					</div>
					<div class="td2">
						<p>{{item.levelName}}</p>
					</div>
				</div>
			</div>
			<div class="pagination">
				<el-pagination style="color: white" background @current-change="currentChange3"
					@size-change="sizeChange3" :current-page.sync="current3" :page-sizes="[10, 15, 20]"
					layout="total, sizes, prev, pager, next, jumper" :total="total3">
				</el-pagination>
			</div>

		</div>

		<div v-if="project==99">
			<div style="width: 100%;display: flex;justify-content: space-between;align-items: center;">
				<div class="title">
					<span @click="project=0" style="color: rgba(43, 222, 201, 0.4);cursor: pointer;">公司列表&nbsp; /
						&nbsp;</span>
					<span v-if="projects>1" @click="project=1"
						style="color: rgba(43, 222, 201, 0.4);cursor: pointer;">{{xmcj1}}&nbsp;
						/ &nbsp;</span>
					<span v-if="projects>2" @click="project=2"
						style="color: rgba(43, 222, 201, 0.4);cursor: pointer;">{{xmcj2}}&nbsp;
						/ &nbsp;</span>
					<span v-if="projects>3" @click="project=3"
						style="color: rgba(43, 222, 201, 0.4);cursor: pointer;">{{xmcj3}}&nbsp;
						/ &nbsp;</span>
					<span style="color: rgba(43, 201, 222, 1);cursor: pointer;">设备列表</span>
				</div>
				<div style="display: flex;">
					<div @click="exportss(0)" class="prev">导出设备</div>
					<div @click="exportss(1)" v-if="Sbid == 1" class="prev">导出能源数据</div>
					<div @click="exportss(2)" v-if="Sbid == 0" class="prev">导出环境数据</div>
					<div @click="project=3" class="back">返回</div>
				</div>

			</div>
			<sblb ref="sblbRef" :hjjc_current="hjjc_current" @handleTb99="handleTb99" :levelId="levelId" @handleSelectEquipment="handleSelectEquipment"
				:projects="projects" :projectId="projectId1">
				<template scope="val">
					<div>{{cheng(val.msg)}}</div>
				</template>
			</sblb>
		</div>

		<div v-if="project==999">
			<div style="width: 100%;display: flex;justify-content: space-between;align-items: center;">
				<div class="title">
					<span @click="project=0" style="color: rgba(43, 222, 201, 0.4);cursor: pointer;">公司列表&nbsp; /
						&nbsp;</span>
					<span v-if="projects>1" @click="project=1"
						style="color: rgba(43, 222, 201, 0.4);cursor: pointer;">{{xmcj1}}&nbsp;
						/ &nbsp;</span>
					<span v-if="projects>2" @click="project=2"
						style="color: rgba(43, 222, 201, 0.4);cursor: pointer;">{{xmcj2}}&nbsp;
						/ &nbsp;</span>
					<span v-if="projects>3" @click="project=3"
						style="color: rgba(43, 222, 201, 0.4);cursor: pointer;">{{xmcj3}}&nbsp;
						/ &nbsp;</span>
					<span v-if="projects>99" @click="project=99"
						style="color: rgba(43, 222, 201, 0.4);cursor: pointer;">设备列表&nbsp; / &nbsp;</span>
					<span style="color: rgba(43, 201, 222, 1);cursor: pointer;">设备详情</span>
				</div>
				<div style="display: flex;">

					<div @click="project=99" class="back">返回</div>
				</div>
			</div>
			<sbxq :hjjc_current="hjjc_current" :sbId="sbId" :projects="projects" :projectId="projectId1"></sbxq>
		</div>
	</div>
</template>

<script>
	import sblb from '/src/views/SBGL/sbxxglDetail.vue'
	import sbxq from '/src/views/SBGL/sbxxglDetail2.vue'
	export default {
		name: 'index1',
		components: {
			sblb,
			sbxq
		},
		data() {
			return {
				hjjc_current: 0, // 0环境 1能源
				sbId: 0, // 设备id
				project: 0,
				projects: 0,
				tableData0: [],
				current0: 1,
				size0: 10,
				total0: 0,
				tableData1: [],
				projectId1: null,
				current1: 1,
				size1: 10,
				total1: 0,
				// 22222
				tableData2: [],
				projectId2: null,
				current2: 1,
				size2: 10,
				total2: 0,
				// 3333333333
				tableData3: [],
				projectId3: null,
				current3: 1,
				size3: 10,
				total3: 0,
				levelId: null,
				Sbid: null,
				xmcj1: '项目层级一',
				xmcj2: '项目层级二',
				xmcj3: '项目层级三',
        selectEquipmentIds:[],
        selectTypeId: null
			}
		},
		mounted() {
			this.getPage()
		},
		methods: {
			cheng(id) {
				this.Sbid = id
			},
			handleTb99(e) {
				this.sbId = e.sbId
				this.hjjc_current = e.hjjc_current
				this.project = 999
			},
			getPage() {
				// 获取项目列表
				this.$newPost('/project/page', {
					"page": this.current0,
					"size": this.size0
				}).then(res => {
					this.tableData0 = res.data.records
					this.total0 = res.data.total
				})
			},
			currentChange0(e) {

				this.current0 = e
				this.getPage()
			},
			sizeChange0(e) {
				this.size0 = e
				this.getPage()
			},
			// 点击表格
			handleTb0(item) {
				this.xmcj1 = item.projectName // 面包屑名称
				this.projectId1 = item.id
				this.levelId = null
				// 页码 页数还原
				this.current1 = 1
				this.size1 = 10
				this.getLevel1()
			},
			// 分页查询层级列表
			// 11111111111111
			getLevel1() {
				var obj = {
					"page": this.current1,
					"size": this.size1,
					parentId: 0,
					projectId: this.projectId1,
				}
				this.$newPost('/level/page', obj).then(res => {
					if (res.data.records.length > 0) {
						this.tableData1 = res.data.records
						this.total1 = res.data.total
						this.project = 1
					} else {
						this.project = 99
						this.projects = 1
					}
				})
			},
			currentChange1(e) {
				console.log(e)
				this.current1 = e
				this.getLevel1()
			},
			sizeChange1(e) {
				this.size1 = e
				this.getLevel1()
			},
			// 点击表格
			handleTb1(item) {
				this.xmcj2 = item.levelName // 面包屑名称
				this.projectId2 = item.projectId
				this.parentId2 = item.id
				this.levelId = item.id
				// 页码 页数还原
				this.current2 = 1
				this.size2 = 10
				this.getLevel2()
			},

			// 2222222222
			getLevel2() {
				var obj = {
					"page": this.current2,
					"size": this.size2,
					parentId: this.parentId2,
					projectId: this.projectId2,
				}
				this.$newPost('/level/page', obj).then(res => {
					if (res.data.records.length > 0) {
						this.tableData2 = res.data.records
						this.total2 = res.data.total
						this.project = 2
					} else {
						this.project = 99
						this.projects = 2
					}
				})
			},
			currentChange2(e) {
				console.log(e)
				this.current2 = e
				this.getLevel2()
			},
			sizeChange2(e) {
				this.size2 = e
				this.getLevel2()
			},

			handleTb2(item) {
				console.log(item)
				this.xmcj3 = item.levelName // 面包屑名称
				this.projectId3 = item.projectId
				this.parentId3 = item.id
				this.levelId = item.id
				// 页码 页数还原
				this.current3 = 1
				this.size3 = 10
				this.getLevel3()
			},

			// 3333333333
			getLevel3() {
				var obj = {
					"page": this.current3,
					"size": this.size3,
					parentId: this.parentId3,
					projectId: this.projectId3,
				}
				this.$newPost('/level/page', obj).then(res => {
					console.log(res)
					if (res.data.records.length > 0) {
						this.tableData3 = res.data.records
						this.total3 = res.data.total
						this.project = 3
					} else {
						this.project = 99
						this.projects = 3
					}
				})
			},
			currentChange3(e) {
				console.log(e)
				this.current3 = e
				this.getLevel3()
			},
			sizeChange3(e) {
				this.size3 = e
				this.getLevel3()
			},
			handleTb3(item) {
				this.project = 99
				this.projects = 4
				this.levelId = item.id

			},
      handleSelectEquipment(selectEquipmentIds){
        this.selectEquipmentIds = selectEquipmentIds
        console.log(selectEquipmentIds)
      },
			exportss(id) {
        if(this.selectEquipmentIds.length<1){
          this.$message({
          	message: "请选择要导出的设备",
          	type: 'error'
          });
          return;
        }
				var page = this.$refs.sblbRef.facility_page
				var size = this.$refs.sblbRef.facility_size
				var obj = {
					"levelId": this.levelId,
					"page": page,
					"projectId": this.projectId1,
          "equipmentIds": this.selectEquipmentIds,
					"size": size,
					"type": this.Sbid
				}
				var that = this;
				this.$newPost('/equipment/export/' + id, obj).then(res => {
					if (id == 0) {
						if (res.success && res.data.excelUrl != null) {
							window.open(res.data.excelUrl)
						}else{
							this.$message({
								message: res.message,
								type: 'error'
							});
						}
					} else if (id == 1 && res.success ) {
						if (res.data.excelEnergyUrl != null) {
							window.open(res.data.excelEnergyUrl)
						}else{
							this.$message({
								message: "所选设备未上传数据",
								type: 'error'
							});
						}
					} else if (id == 2 && res.success ) {
						if (res.data.excelEnvironmentUrl != null) {
							window.open(res.data.excelEnvironmentUrl)
						}else{
							this.$message({
								message: "所选设备未上传数据",
								type: 'error'
							});
						}
					}else{
            this.$message({
            	message: res.message,
            	type: 'error'
            });
          }
				})
			},

		},
	}
</script>

<style lang="scss" scoped>
	.bg {
		background-color: rgba(43, 201, 222, 0.6);
	}

	.bg1 {
		background-color: rgba(162, 162, 162, 0.1);
	}

	.bg2 {
		background: rgba(14, 242, 242, 0.25);
	}

	.containerx {
		color: #ffffff;
		overflow: auto;
		background-color: #192534;
	}

	.title {
		font-size: 1.8rem;
		font-weight: 500;
		margin-left: 2rem;
		//margin-top: 2rem;
		height: 5rem;
		line-height: 5rem;
	}

	.table {
		width: 100rem;
		height: 34rem;
		overflow: auto;
		margin: 0 auto;

		.tr {
			display: flex;
			padding: .5rem 0;
			// margin-top: 1rem;

			.td1 {
				font-size: 0.9rem;
				width: 6.6rem;
				height: 2rem;
				// margin-right: 0.4rem;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.td2 {
				font-size: 0.9rem;
				width: 147.6rem;
				height: 2rem;
				display: flex;
				align-items: center;
				padding-left: 2rem;
			}
		}

		.tr:hover {
			background-color: rgba(117, 117, 117, 0.4);
		}
	}

	.pagination {
		display: flex;
		justify-content: flex-end;
		margin-right: 1rem;
	}

	.back {
		border: 1px solid #1CB8B8;
		border-radius: 0.5rem;
		width: 6.7rem;
		height: 3.8rem;
		line-height: 3.8rem;
		text-align: center;
		color: rgba(28, 184, 184, 1);
		font-size: 0.9rem;
		margin-right: 2rem;
		cursor: pointer;
	}

	.prev {
		cursor: pointer;
		background: #2BC9DE;
		border-radius: 0.5rem;
		height: 4rem;
		line-height: 4rem;
		text-align: center;
		font-size: 0.9rem;
		color: #FFFFFF;
		margin-right: 1rem;
		padding: 0px 0.5rem 0px 0.5rem;
	}
</style>
